import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { filterByTag } from "@common/helpers/notion/notion-utils";
import Tag from "@components/UI-Elements/Tag/Tag";
import LinkList from "@components/UI-Elements/Lists/LinkList";
import { Edge } from "@common/types/notion";

type Props = {
  filter?: string[];
};
const CryptoLinkList: React.FC<Props> = ({ filter }) => {
  const data = useStaticQuery(
    graphql`
      {
        allNotion(filter: {properties: {Date: {type: {ne: "date"}}}}) {
          edges {
            node {
              properties {
                URL {
                  value
                }
                Tags {
                  key
                  value {
                    name
                    color
                  }
                }
              }
              title
              childMarkdownRemark {
                excerpt
              }
            }
          }
        }
      }
    `
  );

  const links = data.allNotion.edges
    .map((edge: Edge) => edge.node)
    .filter(filterByTag(filter[0]))
    .map((node: any) => {
      const tags = node.properties.Tags;

      return {
        href: node.properties?.URL?.value,
        title: (
          <div className="py-1">
            <p className="font-bold" title={node.childMarkdownRemark.excerpt}>
              {node.title}
              {tags.value.map((tag: any) => (
                <Tag key={tag.name} title={tag.name} />
              ))}
            </p>
          </div>
        ),
        external: true,
      };
    });

  return (
    <LinkList links={links.map((page: any) => ({ ...page, external: true }))} />
  );
};
export default CryptoLinkList;
