import { NotionProperty, NotionTag, NotionPropertyDate } from "@common/types/notion";
import dayjs from "dayjs";

export type FilterNotionNode = {
  properties: {
    Tags: NotionProperty<NotionTag[]>;
    Date: NotionProperty<NotionPropertyDate>;
  };
};
export const filterByTag = (filterTag?: string) => (node: FilterNotionNode) => {
  const tags = node.properties?.Tags.value || [];
  if (!tags || !filterTag) return true;
  return tags.map((item) => item.name?.toLocaleLowerCase()).includes(filterTag?.toLocaleLowerCase());
};

export const filterByStartDate = (filterDate: Date, format = 'YYYYMM') => (node: FilterNotionNode) => {
  const startDate = node.properties?.Date.value?.start;
  if (!startDate) return true;
  const dayjsDate = dayjs(startDate);

  return dayjsDate.format(format) >= dayjs(filterDate).format(format);
}