import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import { useFilters } from "../../common/hooks/useFilters";

import Layout from "../../components/layout";
import CryptoLinkList from "../../components/Modules/wissen/link-container";
import SEO from "../../components/seo";
import Card from "../../components/UI-Elements/Card";
import TagButton from "../../components/UI-Elements/Tag/TagButton";

const filters = ["crypto", "nft", "web3", "blockchain", "metaverse", "tool"];
const title = "Meine Linksammlung";

const CryptoLinks: React.FC = () => {
  const { filter, onSelect } = useFilters({ allowMultiple: false });

  return (
    <Layout>
      <SEO title={title} />
      <Section>
        <div className="text-center">
          <Title icon="📄">{title}</Title>
          <p>
            Das ist meine ungefilterte Liste an Webseiten, Projekten und
            Informationen, die ich für mich gespeichert habe.
          </p>
          <div className="my-4">
            <strong>Nach Tags filtern:</strong>
            {filters.map((item) => (
              <TagButton
                key={item}
                title={item}
                onClick={onSelect}
                selected={filter.includes(item)}
              />
            ))}
          </div>
        </div>
        <Card prose={false}>
          <CryptoLinkList filter={filter} />
        </Card>
      </Section>
    </Layout>
  );
};

export default CryptoLinks;
